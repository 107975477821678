import HomeImage12 from "@/img/home_image_1_2.svg";
import HomeImage22 from "@/img/home_image_2_2.svg";
import HomeImage23 from "@/img/home_image_2_3.svg";
import logoPath from "@/img/logo.svg";
import { UploadsProvider } from "@cospex/client/converter/context/UploadsProvider";
import ConverterConfigProvider from "@cospex/client/converter/provider";
import { initDictionary } from "@cospex/client/helpers/dictionary";
import GlobalProvider from "@cospex/client/provider";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import { Routes } from "@generouted/react-router/lazy";
import { createRoot } from "react-dom/client";

import theme from "./Theme";

const container = document.getElementById("app")!;

const languages = [
  { value: "en", label: "EN 🇬🇧" },
  { value: "fr", label: "FR 🇫🇷" },
  { value: "it", label: "IT 🇮🇹" },
  { value: "de", label: "DE 🇩🇪" },
  { value: "es", label: "ES 🇪🇸" },
];

initDictionary(languages);

createRoot(container).render(
  <GlobalProvider
    theme={theme}
    config={{
      logo: {
        src: logoPath,
      },
      languages,
    }}
  >
    <ConverterConfigProvider
      value={{
        palette: {
          dark: "#2B2E4A",
          darkest: "#1F2238",
        },
        homepage: {
          section: {
            stepsWithImages: {
              bgColor: "#EEF2F6",
              color: "#000",
              homeImage12: HomeImage12,
              homeImage22: HomeImage22,
              homeImage23: HomeImage23,
            },
            security: {
              imageFilter: "grayscale(100%)",
            },
          },
        },
      }}
    >
      <UploadsProvider>
        <Routes />
      </UploadsProvider>
    </ConverterConfigProvider>
  </GlobalProvider>
);
